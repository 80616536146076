import { useState } from 'react';
import { Title } from 'react-admin';
import { LoadingButton } from '@mui/lab';
import { IconButton, TextField } from '@mui/material';
import { Delete as DeleteIcon, FindInPageOutlined, UploadFile as UploadFileIcon } from '@mui/icons-material';
import { ERROR, EXCEL_FORMAT, SUCCESS, SELECT_FILE,UPLOAD_FILE } from '../constants';
import { uploadExcelData } from '../api/api';
import { parseFile } from './validateFile';
import './uploadFile.css';

const UploadFile = () => {
    const [file, setFile] = useState();
    const [isLoadingFile, setIsLoadingFile] = useState(false);
    const [messageResult, setMessageResult] = useState('');

    const updateMessage = (message, status) => {
        setMessageResult(message || '');
        document.getElementById('uploadMessageResult').className = status || '';
    };

    const uploadData = parsedFile => {
        uploadExcelData(parsedFile, file.name)
            .then(() => {
                setFile();
                updateMessage(SUCCESS.UPLOAD, 'success');
            })
            .catch(() => updateMessage(ERROR.UPLOAD, 'error'))
            .finally(() => setIsLoadingFile(false));
    };

    const onUploadFile = () => {
        if (!file) {
            updateMessage(SELECT_FILE);
            return;
        }
        if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls')) {
            updateMessage(ERROR.EXCEL_FORMAT, 'error');
            return;
        }

        setIsLoadingFile(true);
        parseFile(file)
            .then(uploadData)
            .catch(e => {
                updateMessage(e.message, 'error');
                setIsLoadingFile(false);
            });
    };

    return <div className="uploadFilePageContainer">
        <Title title={UPLOAD_FILE.MENU} />
        <div className={'uploadFileForm'}>
            <div style={{ marginBottom: '10px' }}>
                <IconButton component={'label'}>
                    <input
                        value={''}
                        hidden accept={'.xlsx, .xls'}
                        type={'file'}
                        onChange={e => { setFile(e.target.files[0]); updateMessage(); }}
                    />
                    <FindInPageOutlined sx={{ fontSize: 30}} />
                </IconButton>
                <TextField
                    InputProps={{ readOnly: true, placeholder: EXCEL_FORMAT }}
                    variant={'standard'}
                    value={(file && file.name) || ''}
                />
                <IconButton component={'label'} onClick={() => { setFile(); updateMessage(); }}>
                    <DeleteIcon />
                </IconButton>
            </div>
            <LoadingButton
                loading={isLoadingFile}
                variant={'contained'}
                startIcon={<UploadFileIcon />}
                onClick={onUploadFile}
            >
                {UPLOAD_FILE.MENU.toUpperCase()}
            </LoadingButton>
            <p id={'uploadMessageResult'}>{messageResult}</p>
        </div>
    </div>;
};

export default UploadFile;